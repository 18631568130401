<template>
    <div id="FindPassword" class="has_fixed_bottom_box">
        <div class="container">
            <div class="step01" v-if="step == 1">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t('findPwd.1')}}
                    </div></h3>
                    <p>{{$t('findPwd.2')}}<br></p>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>{{$t('findPwd.4')}}</label>
                        <input type="email" class="form-control" v-model="email" :placeholder="$t('signup.3')" required>
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <button type="button" class="btn btn-block btn-primary " @click="passwordSecutity"><small>{{$t('findPwd.5')}}</small></button>
                </div>
            </div>
            <div class="step02" v-if="step == 2">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t('findPwd.6')}}
                    </div></h3>
                    <p>
                        {{$t('findPwd.7')}}<br>
                        {{$t('findPwd.8')}}
                    </p>
                </div>
                <div class="form_box">
                    <div class="form-group line">
                        <label>{{$t('findPwd.9')}}</label>
                        <input type="text" class="form-control" v-model="code" :placeholder="$t('findPwd.9')" required>
                    </div>
                </div>
                <div class="fixed_bottom_box p-3">
                    <button type="button" class="btn btn-block btn-primary " @click="passwordConfirm"><small>{{$t('findPwd.10')}}</small></button>
                </div>
            </div>
            <div class="step03" v-if="step == 3">
                <div class="top_txt_box mb-5 mt-4">
                    <h3><div class="">
                        {{$t('findPwd.11')}}
                    </div></h3>
                    <p>
                        {{$t('findPwd.12')}}<br>
                        {{$t('findPwd.13')}}
                    </p>
                </div>
                <div class="fixed_bottom_box p-3">
                    <router-link :to="`/${$i18n.locale}/signin`" class="btn btn-block btn-primary "><small>{{$t('findPwd.14')}}</small></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data(){
        return{
            title: this.$t('findPwd.1'),
            prevP:'type2',
            pageD: false,
            step: 1,
            email: "",
            code: ""
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
    },
    methods: {
        passwordSecutity: function() {
            const api = this.$store.state.baseURL;
            const email = this.email;

            if(email == ""){
                this.$alert(this.$t('findPwd.15'));
                return false;
            }

            this.$http.post(`${api}/member/common/login/email_security`,{email}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.step = 2;
                        }
                        else {
                            this.$alert(this.$t('findPwd.16'));
                            return;
                        }
                    }
                }
            )
        },
        passwordConfirm: function() {
            const api = this.$store.state.baseURL;
            const email = this.email;
            const code = this.code;

            if(code == ""){
                this.$alert(this.$t('findPwd.17'));
                return false;
            }

            this.$http.post(`${api}/member/common/login/email_code_check`,{email,code}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.passwordReset();
                        }
                        else {
                            this.$alert(this.$t('findPwd.18'));
                            return;
                        }
                    }
                }
            )
        },
        passwordReset: function() {
            const api = this.$store.state.baseURL;
            const email = this.email;

            this.$http.post(`${api}/member/login/password_reset`,{email}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.step = 3;
                        }
                        else {
                            this.$alert(this.$t('findPwd.19'));
                            this.step = 1;
                        }
                    }
                }
            )
        }
    },
}
</script>
<style scoped>
    .fixed_bottom_box{
        background-color: #fff;
    }
</style>