<template>
    <div id="SignUp" class="has_fixed_bottom_box">
        <div class="container">
            <div class="top_txt_box mb-5 mt-4">
                <h3>{{ $t('signup.1') }}</h3>
                <p>{{ $t('signup.2') }}</p>
            </div>
            <div class="form_box">
                <div class="form-group line mb-4 pb-1">
                    <label>{{$t('signup.5')}}</label>
                    <input type="email" class="form-control" ref="emails" :placeholder="$t('signup.3')" v-model="email" @focusout="CheckEmail()" required>
                    <p class="error" v-if="emailErr">{{$t('signup.6')}}</p>
                </div>
                <div class="form-group line mb-4 pb-1">
                    <label>{{$t('signup.10')}}</label>
                    <input type="password" class="form-control" ref="password" :placeholder="$t('signup.3')" v-model="password" @focusout="CheckPassword()" required>
                    <p class="error" v-if="passwordErr">{{$t('signup.11')}}</p>
                </div>
                <div class="form-group line mb-4 pb-1">
                    <label>{{$t('signup.12')}}</label>
                    <input type="password" class="form-control" ref="confirmPwd" :placeholder="$t('signup.3')" v-model="confPassword" @focusout="CheckPasswordConfirm()" required>
                    <p class="error" v-if="pwdConfErr">{{$t('signup.13')}}</p>
                </div>
                <div class="form-group line">
                    <label>{{$t('signup.14')}}</label>
                    <input type="password" class="form-control" ref="pin" :placeholder="$t('signup.3')" v-model="pin" required @click="infoChk=true" @focus="infoChk=true">
                    <p class="error" v-if="pinErr">{{$t('signup.15')}}</p>
                </div>
            </div>
            <div class="fixed_bottom_box p-3">
                <button type="button" class="btn btn-block btn-primary" @click="joinCheck()"> <small>{{$t('signup.19')}}</small></button>
            </div>
            <div class="infoPp wtdPw" v-if="infoChk">
                <div class="dimm" @click="infoChkOff()"></div>
                <div class="content pt-4">
                    <i class="fas fa-times" @click="infoChk = false"></i>
                    <div class="circle">
                        <i class="fas fa-lock"></i>
                    </div>
                    <p class="text-center py-3">{{$t('signup.21')}}<br> {{$t('signup.22')}}</p>
                    <input type="password" pattern="[0-9]{8}" maxlength="8" inputmode="numeric" class="wtdPw form-control mx-auto mb-3 text-center" style="max-width:250px; letter-spacing: 10px;" ref="pin" placeholder="●●●●●●●●" v-model="pin" @focusout="PinCodeChaeck()" @keyup.enter="infoChk = false" required>
                    <button type="button" style="max-width:250px;" class="btn mx-auto btn-block btn-primary mb-4" @click="infoChk = false"><small>{{$t('signup.19')}}</small></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title: this.$t('signup.20'),
            prevP:'type02',
            pageD: false,
            lang: this.$route.params.lang,

            email: '',
            password: '',
            confPassword: '',
            pin: '',

            emailErr: false,
            passwordErr: false,
            pwdConfErr: false,
            pinErr: false,

            infoChk: false,
            infoPp: false,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
    },
    methods: {
        maxLengthCheck(object){
            if (object.value.length > object.maxLength){
                object.value = object.value.slice(0, object.maxLength);
            }    
        },
        infoPpOn: function(item){
            this.infoPp = true;
        },
        infoPpOff: function(){
            this.infoPp = false;
        },
        infoChkOff: function(){
            this.infoChk = false;
        },     
        CheckEmail : function(){
            var character = this.email;
            if(character == ""){
                this.emailErr = true;
            }
            else{
                var check = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(character);
                if(check){
                    this.emailErr = false;

                }
                else {
                    this.emailErr = true;
                }
            }
        },   
        CheckPassword : function() {            
            var character = this.password;
            if(character == ""){
                this.passwordErr = true;
            }
            else {
                var check = /^(?=.*\d{1,50})(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*[~`!@#$%^&*()-+=]).{10,50}$/.test(character);
                if(check){
                    this.passwordErr = false;
                }
                else{
                    this.passwordErr = true;
                }
            }
        },
        PinCodeChaeck(){
            const pin = this.pin;
            if(this.pin  ==''){
                this.pinErr = true;
            }
            if(pin.length != 8){
                this.pinErr = true;
            }
            else{
                this.pinErr = false;
            }
        },
        CheckPasswordConfirm: function() {
            if(this.password === this.confPassword){
                this.pwdConfErr = false;
            }
            else {
                this.pwdConfErr = true;
            }
        },
        
        joinCheck(){
            const api = this.$store.state.baseURL;
            const email = this.email;
            const password = this.password;
            const pin = this.pin;

            if(this.email == ""){
                this.$alert(this.$t("findPwd.15"));
                return false;
            }

            if(this.emailErr){
                this.$alert(this.$t('signup.6'));
                return false;
            }

            if(password ==""){
                this.$alert(this.$t('signupAlert.2'));
                return false;
            }

            if(this.passwordErr){
                this.$alert(this.$t('signup.11'));
                return false;
            }

            if(this.pwdConfErr){
                this.$fire({html:this.$t('signupAlert.3')});
                return false;
            }

            if(pin ==""){
                this.$alert(this.$t('signupAlert.6'));
                return false;
            }
            if(this.pinErr){
                this.$alert(this.$t('signupAlert.7'));
                return false;
            }            

            const lang = this.lang;
            this.$http.post(`${api}/member/sign`,{email,password,pin,lang}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                        this.$fire({title:this.$t('signupAlert.9'),html:this.$t('signupAlert.10')}).then(
                            ()=>{
                                this.$router.push({ name: 'Signin' })
                            }
                        )
                        }else if(res.data.code=='110'){
                            this.$alert(this.$t('signupAlert.11'));
                        }
                    }
                }
            )
        },
    },
}
</script>
<style scoped>
    .infoPp.wtdPw .content{
        /* height: calc(100% - 50px);
        max-height: inherit; */
        height: 100%;
        border-radius: 0;
        max-height: inherit;
        left: 0;
        transform: translateX(0);
        padding-top: 100px !important;
    }
    .infoPp.wtdPw input.wtdPw{
        -webkit-text-security: disc;
    }
    .infoPp.wtdPw input.wtdPw::placeholder{
        font-size:8px;
        color:#ddd;
    }
    .infoPp.wtdPw .circle{
        width: 60px;
        height: 60px;
        border: 5px solid #171c61;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #171c61;
        font-size:1.3em;
        margin: 0 auto;
    }
    .infoPp.wtdPw .fa-times{
        position: absolute;
        top:1em;
        right:1em;
        font-size: 1.5em;
    }
    .error {
        font-size: 12px;
        color: #ff6363;
        margin-left: 5px;
    }
    
    .fixed_bottom_box{
        background-color: #fff;
    }
</style>