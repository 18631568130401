<template>
	<div></div>
</template>
<script>
	export default {
		data(){
			return{
			}
		},
		mounted(){
			this.Check();
		},
		methods : {
			Check : function(){
				const api =  this.$store.state.baseURL;
                const no =  this.$route.query.no;
                const code =  this.$route.query.code;
                const sec_no =  this.$route.query.sec_no;
				this.$http.post(`${api}/member/email/check`,{no,code,sec_no}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								this.$alert(this.$t('package.31')).then(
									()=>{
										this.$router.push({path:'/'+this.$i18n.locale+'/signin'});
									}
								)
							}else if(res.data.code=='110'){
								this.$alert(this.$t('commision.6')).then(
									()=>{
										this.$router.push({path:'/'+this.$i18n.locale+'/signin'});
									}
								)
							}else if(res.data.code=='100'){
								this.$alert(this.$t('commision.5')).then(
									()=>{
										this.$router.push({path:'/'+this.$i18n.locale+'/signin'});
									}
								)
							}
						}
					}
				).catch(() => {});
			}
		}
	}
</script>
<style scoped>
	.main_balance_total span {display: inline-block;}
</style>