<template>
    <div class="wrap">
        <TopLogin/>
        <router-view ref="componentRef"></router-view>
    </div>
</template>

<script>


import TopLogin from '@/components/common/TopLogin.vue'
export default {
    name: 'Sign',
    components:{
        TopLogin
    },
    mounted(){
        const login  = this.$store.state.login
    
        if(login !=false){
            this.$router.push({name:'Dashboard'});
        }
    }
}
</script>
<style scoped>
.wrap{
    background-color: #fff;
}
</style>