<template>
    <div id="SignIn" class="overflow_auto">
        <div class="logo_box">
            <img src="@/assets/img/logo/logo.png" alt="">
        </div>        
        <div class="bottom_box">
            <div class="absolute_box w-100">
                <div class="step01" v-if="loginArea">
                    <div class="form_box">
                        <div class="form-group line">
                            <label>{{ $t('signin.1') }}</label>
                            <input type="text" class="form-control" v-model="email" @focusout="CheckEmail" :placeholder="$t('signin.1')">
                            <p class="error" v-if="emailErr">{{$t('signup.6')}}</p>
                        </div>
                        <div class="form-group line">
                            <label>{{ $t('signin.2') }}</label>
                            <input type="password" class="form-control" v-model="password" @keyup.enter="Login()" :placeholder="$t('signin.2')">
                        </div>
                        <div class="form-group line">
                            <input type="checkbox" id="id_remember" name="scales" class="mr-2" v-model="remember_id">
                            <label for="id_remember" class="pale_txt mb-1">{{ $t('signin.14') }}</label>
                        </div>
                        <div class="secu_code_box d-flex">
                            <div class="left w-50 mr-2">
                                <div class="code border w-100">
                                    {{secu_num}}
                                </div>
                            </div>
                            <div class="right w-50">
                                <div class="form-group line">
                                    <div class="d-flex justify-content-between mb-1">
                                        <label class="pale_txt">{{ $t('signin.3') }}</label>
                                        <small class="countdown">{{ ShowTime(secu_count_num) }}</small>
                                    </div>
                                    <input type="number" class="form-control border" v-model="code" @keyup.enter="Login()" :placeholder="$t('signin.4')">
                                </div>
                            </div>
                        </div>
                    </div>
    

                    <!-- 로그인 -->
                    <button type="button" class="btn btn-block btn-primary mb-4" v-if="loginArea" @click="Login()"><small>{{ $t('signin.5') }}</small></button>
                    <div class="text-center">
                        <router-link :to="`/${$i18n.locale}/signup`" class="mr-3">{{ $t('signin.6') }}</router-link>
                        <router-link :to="`/${$i18n.locale}/find-password`">{{ $t('signin.7') }}</router-link>
                    </div>
                    <!-- <div class="pt-5 text-center social_menu">
                        <span class="text_pale small">- {{ $t("signin.13") }} -</span>
                        <ul class="d-flex justify-content-center mt-2 sns_list">
                            <li v-if="mobile" @click="GoogleLogin">
                                <img src="@/assets/img/social/Google.png" alt="google">
                            </li>
                            <li v-else>
                                <GoogleLogin class="external_item px-0" :params="googleParams" :onSuccess="GoogleLogin" :onFailure="GoogleFail">
                                    <img src="@/assets/img/social/Google.png" alt="google"/>
                                </GoogleLogin>
                            </li>
                            <li @click="FacebookLogin"><img src="@/assets/img/social/Facebook.png" alt="facebook"></li>
                            <li id="naver_id_login"></li>
                            <li @click="KakaoLogin"><img src="@/assets/img/social/Kakao.png" alt="kakao"></li>
                            <li><img src="@/assets/img/social/Apple.png" alt="apple"></li>
                        </ul>
                    </div> -->
                    <div class="text-center mt-5 mb-3 small">
                        <span class="main_txt p-2 mail_btn" @click="copymail($store.state.FOOTER.EMAIL)">E-MAIL : {{$store.state.FOOTER.EMAIL}}</span>
                    </div>

                    <div class="text-center small">
                        <router-link :to="`/${$i18n.locale}/sign-issue`" class="mr-3 issue"><i class="far fa-exclamation-circle mr-1"></i>{{ $t('signin.11') }}
                        <span class="ml-1" style="color: #bbb;">{{ $t('signin.12') }}</span>
                        </router-link>
                    </div>
                </div>

                <div class="step02" v-if="securityArea">
                    <div class="form_box" v-if="emailArea">
                        <div class="form-group line">
                            <div class="d-flex align-items-center justify-content-between">
                                <label>{{ $t('signin.8') }} {{ $t('signin.9') }}</label>
                            </div>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="email_code" :placeholder="$t('signin.8')+ $t('signin.10')" :disabled="email_chk">
                                <button type="button" class="btn btn-default text-right pr-0" :disabled="email_chk" @click="EmailCodeCheck">{{ $t('signin.9') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="form_box" v-if="otpArea">
                        <div class="form-group line">
                            <label>OTP {{ $t('signin.9') }}</label>
                            <div class="r_btn">
                                <input type="password" class="form-control" v-model="otp_code" :placeholder="`OTP ${$t('signin.10')}`" :disabled="otp_chk">
                                <button type="button" class="btn btn-default text-right pr-0" :disabled="otp_chk" @click="OTPCodeCheck">{{ $t('signin.9') }}</button>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-block btn-primary mb-4" @click="LoginFinish()"><small>{{ $t('signin.5') }}</small></button>
                </div>                

            </div>
        </div>
        <div v-if="site_check">
            <div class="popup_box on">
                <div class="popup" style="background-color: rgb(5 21 61 / 63%);">
                    <div class="content_box">
                        <div class="content">
                            <div class="tit_box mt-5 ml-3">
                                <h6><i class="fas fa-user-shield fa-5x"></i></h6>
                            </div>
                            <div class="txt_box mt-4">
                                <h5 style="font-weight: bolder; color: #3b4c8b;">{{ $t('signinAlert.16') }}</h5>
                                <br>
                                <p style="line-height: 30px; color: #222222; font-size: 13px" v-html="sanitizeHtml($t('signinAlert.17'))"></p>
                                <p style="line-height: 30px; color: #222222; font-size: 13px" v-html="sanitizeHtml($t('signinAlert.18'))"></p>
                                <p style="line-height: 30px; color: #222222; font-size: 13px" v-html="sanitizeHtml($t('signinAlert.19'))"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="popup_box" :class="{on : index == popup.length-1}" v-for="(item, index) in popup.slice().reverse()" :key="index">
                <div class="popup" v-if="item.active">
                    <div class="dimm" @click="item.active = false, addClassOn(index)"></div>
                        <div class="content_box">
                        <p class="top_tit">{{item.title}}</p>
                        <i class="fal fa-times close_icon" @click="item.active = false, addClassOn(index)"></i>
                        <div class="content">
                            <div class="tit_box">
                            <h5>NOTICE</h5>
                            </div>
                            <div class="txt_box">
                            {{item.description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import GoogleLogin from 'vue-google-login';

// import Google from '@/utils/social/Google';
// import Facebook from '@/utils/social/Facebook';
// import Kakao from '@/utils/social/Kakao';
// import Naver from '@/utils/social/Naver';
import sanitizeHtml from 'sanitize-html';

export default {
    components: {
        // GoogleLogin, 
    },
    data() {
        return {
            title: this.$t("signin.5"),
            remember_id : false,
            lang: this.$route.params.lang,
            mem_info: [],
            email: "",
            password: "",
            code: "",
            emailErr: false,

            loginArea:true,
            securityArea:false,

            emailArea:false,
            otpArea : false,
            email_chk:false,
            otp_chk:false,

            l_email:'',
            l_otp : '',
            email_code : '',
            otp_code :'',

            secu_num: 0,
            secu_count_int: 60,
            secu_count_num: 90,

            email_send: false,
            popup: [],
            
            mobile: false,
            site_check: false,

            googleParams: { client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID },
            naverParams: {
                client_id: process.env.VUE_APP_NAVER_CLIENT_ID,
                callback_url: process.env.VUE_APP_NAVEL_CALLBACK_URL+"/"+this.$i18n.locale+"/signin"
            },
            naverClick: false
        }
    },
    created() {
        // Facebook.init();
        // if (!window.Kakao.isInitialized()) Kakao.init();
    },
    mounted(){
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', '/assets/js/appRequest.js')
        document.head.appendChild(recaptchaScript)

        // Naver.init();
        this.$store.dispatch('SETLOGOUT');
        this.SecuCountFn();
        this.secu_num = this.getRandomIntInclusive(10000,99999).toString();
        this.MobileCheck();
        this.SiteCheck();
        const cooki_check = this.$cookies.isKey("remember_id")

        if(cooki_check ==true){
            const id = $cookies.get("remember_id");
            this.email = id;
            this.remember_id = true;
        }
        
        
    },
    methods: {
        MobileCheck() {
            var broswerInfo = navigator.userAgent;            
            if(broswerInfo.indexOf("HYBRID_APPLICATION")>-1){
                try{
                    this.mobile = true;
                }catch(e) {}       

            } else if(broswerInfo.indexOf("HYBRID_IOS")>-1) {
                try{
                    this.mobile = true;
                }catch(e) {
                    this.$alert("MobileCheck Error : "+e);
                }
                
            }else {
                this.mobile = false;
            }
        },
        SetLocale: function(lang){            
            const locale= lang;
            this.$router.push({
                params: {lang: locale}
            }).then(
                ()=>{
                    this.$router.go();
                }
            )            
        },
        sanitizeHtml : sanitizeHtml,
        SiteCheck() {
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/login/site_check`, {}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '500') {this.site_check = true;}
                    else {
                        this.site_check = false;
                        this.GetNoticePopup();
                    }
                }
            })
        },
        GetNoticePopup() {
            const lang = this.lang;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/board/notice/login/popup`, {lang}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        res.data.list.forEach(el => {
                            this.popup.push({ no: el.no, title: el.title, description: el.memo, active: true });
                        });
                    }else if(res.data.code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                    }
                }
            })
        },
        addClassOn(index){
            var popupBox = document.querySelectorAll(".popup_box")
            if( popupBox[index-1] ){
                popupBox[index].classList.remove("on")
                popupBox[index-1].classList.add("on")
            }else{
                popupBox[index].classList.remove("on")
            }
        },
        CheckEmail : function(){
            var character = this.email;
            if(character == ""){
                this.emailErr = true;
            }
            else{
                var check = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(character);
                if(check){
                    this.emailErr = false;

                }
                else {
                    this.emailErr = true;
                }
            }
        },   
        
        Login : function(){
            const api = this.$store.state.baseURL;
            const email = this.email;
            const emailErr = this.emailErr;
            const password = this.password;
            const secu_num = this.secu_num;
            const code = this.code;
            
            if(email ==""){
                this.$alert(this.$t("signinAlert.1"));
                return false;
            }
            
            if(emailErr){
                this.$alert(this.$t("signup.6"));
                return false;
            }

            if(password ==""){
                this.$alert(this.$t("signinAlert.2"));
                return false;
            }

            if(code == ""){
                this.$alert(this.$t("signinAlert.3"));
                return false;
            }

            if(code != secu_num){
                this.$alert(this.$t("signinAlert.4"));
                return false;
            }

            this.$http.post(`${api}/member/login/`,{email,password}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            const mem_info = {token : res.data.token,email: res.data.email, social:''};
                            this.mem_info = mem_info
                            this.l_email = res.data.login_email
                            this.l_otp = res.data.login_otp
                            this.token = res.data.token

                            if(this.l_email =='N' && this.l_otp =='N'){
                                const remember = this.remember_id;
                                
                                if(remember ==true){
                                    this.$cookies.set('remember_id',email);
                                }else{
                                    this.$cookies.remove("remember_id");
                                }
                                    this.$alert(this.$t("signinAlert.5")).then(
                                    ()=>{                                        
                                        this.$store.dispatch('SETMEMBER',{mem_info}).then(
                                            ()=>{
                                            this.$router.push({ name: 'Dashboard' })
                                        });
                                        
                                    }
                                )
                            }else{
                                if(this.l_email =='Y' || this.l_otp =='Y'){
                                    this.loginArea = false;
                                    this.securityArea = true;
                                    if(this.l_email == 'Y'){
                                        this.EmailSecurity()
                                        this.emailArea = true;
                                    }
                                    else{
                                        this.email_chk = true;
                                    }
                                    if(this.l_otp == 'Y'){
                                        this.otpArea = true;
                                    }
                                    else {
                                        this.otp_chk = true;
                                    }
                                }
                            }
                        }else if(res.data.code=='100'){
                            this.$alert(this.$t("signinAlert.6"));
                        }else if(res.data.code=='110'){
                            this.$fire({html:this.$t("signinAlert.7")})
                        }else if(res.data.code=='111'){
                            this.$fire({html:this.$t("signinAlert.7")})
                        }else if(res.data.code=='112'){
                            this.$fire({html:this.$t("signinAlert.7")})
                        }else if(res.data.code=='500'){
                            this.$fire({html:this.$t("signinAlert.16")})
                        }
                    }

                }
            )
        },
        LoginFinish: function() {
            if(!this.email_chk || !this.otp_chk){
                this.$alert(this.$t("signinAlert.8"));
            }
            else {
                const api = this.$store.state.baseURL;
                const email = this.email;
                const password = this.password;
                this.$http.post(`${api}/member/login/`,{email,password}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            const mem_info = {token : res.data.token,email: res.data.email, social:''};
                            this.mem_info = mem_info
                            this.l_email = res.data.login_email
                            this.l_otp = res.data.login_otp
                            this.token = res.data.token
                            if(this.email_chk && this.otp_chk){
                                const remember = this.remember_id;
                                
                                if(remember ==true){
                                    this.$cookies.set('remember_id',email);
                                }else{
                                    this.$cookies.remove("remember_id");
                                }
                                    this.$alert(this.$t("signinAlert.5")).then(
                                    ()=>{                                        
                                        this.$store.dispatch('SETMEMBER',{mem_info}).then(
                                            ()=>{
                                            this.$router.push({ name: 'Dashboard' })
                                        });                                        
                                    }
                                )
                            }
                        }else if(res.data.code=='100'){
                            this.$alert(this.$t("signinAlert.6"));
                        }else if(res.data.code=='110'){
                            this.$fire({html:this.$t("signinAlert.7")})
                        }else if(res.data.code=='111'){
                            this.$fire({html:this.$t("signinAlert.7")})
                        }else if(res.data.code=='112'){
                            this.$fire({html:this.$t("signinAlert.7")})
                        }
                    }

                }
            )
            }
        },
        EmailSecurity : function(){
            const api = this.$store.state.baseURL;
            
            const email = this.email;
            this.$http.post(`${api}/member/common/login/email_security`,{email}).then(
                res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t("signinAlert.9"));
                    }
                }

                }
            )
        },
        EmailCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const email = this.email;
            const code = this.email_code;

            if(code == ''){
                this.$alert(this.$t("signinAlert.10"));
                return;
            }
            
            this.$http.post(`${api}/member/common/login/email_code_check`,{email,code}).then(
            res => {
                if(res.status==200){
                    
                    if(res.data.code=='200'){
                        this.$alert(this.$t("signinAlert.11")).then(
                        ()=>{
                            this.email_chk = true;
                            if(this.email_chk && this.otp_chk) this.auth_chk = true;
                        }
                    )
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t("signinAlert.12"))
                    }
                }
            })
        },
        OTPCodeCheck : function(){
            const api = this.$store.state.baseURL;
            const email = this.email;
            const code = this.otp_code;

            if(code == ''){
                this.$alert(this.$t("signinAlert.13"));
                return;
            }
            this.$http.post(`${api}/member/common/login/otp_code_check`,{email,code}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.$alert(this.$t("signinAlert.14")).then(
                        ()=>{
                            this.otp_chk = true;
                            if(this.email_chk && this.otp_chk) this.auth_chk = true;
                        })
                    }else if(res.data.code=='100'){
                        this.$alert(this.$t("signinAlert.15"));
                    }
                }

            })
        },
        getRandomIntInclusive :function(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min; //최댓값도 포함, 최솟값도 포함
        },
        SecuCountFn : function(){
            this.secu_count_int = setInterval(function(){
                if( this.secu_count_num > 0 ){
                    this.secu_count_num--;
                }else{
                    // 인터벌 종료
                    // clearInterval(this.secu_count_int)
                    this.secu_num = this.getRandomIntInclusive(10000,99999).toString();
                    this.secu_count_num = 90;
                }
            }.bind(this), 1000);
        },
        ShowTime : function(x){
            var min = Math.floor( x/60 );
            var sec = x%60;

            if (sec.toString().length==1) sec = "0" + sec;

            if(x >= 60){
                return min +":"+ sec
            }else{
                // this.ShowTime(this.secu_count_num);
                return "0:"+sec
            }
        },
        yetfunction(){
            this.$alert(this.$t("dashboard.16"));
        },
        copymail: function (addr) {
            this.$copyText(addr).then( 
            () =>{
                this.$fire({title:this.$t('mywallet.18'),html : addr});
            })
        },    
        async KakaoLogin() {
            var broswerInfo = navigator.userAgent;            
            if(broswerInfo.indexOf("HYBRID_APPLICATION")>-1){
                try{
                    // window.myHybrid.kakaoLogin();
                    window.mainHybrid.kakaoLogin();
                }catch(e) {
                    
                }       

            } else if(broswerInfo.indexOf("HYBRID_IOS")>-1) {
                try{
                    window.webkit.messageHandlers.kakaoLogin.postMessage({});
                }catch(e) {
                    this.$alert("accessRequest Error : "+e);
                }
                
            }else {
                Kakao.Login();
            }
        },
        KakaoAppLogin(error, email) {
            if(error == "0"){
                Kakao.AppLogin(email);
            } else {
                // 로그인 실패
                this.$alert("카카오 로그인을 실패했습니다.");
            }
        },  
        async FacebookLogin() {
            var broswerInfo = navigator.userAgent;            
            if(broswerInfo.indexOf("HYBRID_APPLICATION")>-1){
                try{
                    window.mainHybrid.FacebookLogin();
                }catch(e) {
                    this.$alert("accessRequest Error : "+e)
                }       

            } else if(broswerInfo.indexOf("HYBRID_IOS")>-1) {
                try{
                    window.webkit.messageHandlers.FacebookLogin.postMessage({});
                }catch(e) {
                    this.$alert("accessRequest Error : "+e);
                }
                
            }else {
                Facebook.Login();
            }
        },    
        FacebookAppLogin(error, token, email) {
            if(error == "0"){
                Facebook.AppLogin(token, email);
            } else {
                // 로그인 실패
                this.$alert("페이스북 로그인을 실패했습니다.");
            }
        },  
        async GoogleLogin(googleUser) {
            var broswerInfo = navigator.userAgent;            
            if(broswerInfo.indexOf("HYBRID_APPLICATION")>-1){
                try{
                    window.mainHybrid.googleLogin();
                }catch(e) {
                    this.$alert("accessRequest Error : "+e)
                }       

            } else if(broswerInfo.indexOf("HYBRID_IOS")>-1) {
                try{
                    window.webkit.messageHandlers.googleLogin.postMessage({});
                }catch(e) {
                    this.$alert("accessRequest Error : "+e);
                }
                
            }else {
                Google.Login(googleUser);
            }
        },
        GoogleAppLogin(error, token, email) {
            if(error == "0"){
                Google.AppLogin(token, email);
            } else {
                // 로그인 실패
                this.$alert("구글 로그인을 실패했습니다.");
            }
        },  
        servicePending() {
            this.$alert(this.$t("menu.23"));
        }


    },
}
</script>

<style>
    .wrap{
        padding-top: 0;   
    }
    #SignIn{
        background-color:#fff;
        padding: 0 15px;
        min-height: 500px;
        width: 100%;
        min-height: 100vh;
        padding-top: 50px;
        top:0;
    }
</style>
<style scoped>
    *{
    -ms-overflow-style: none;
    }
    ::-webkit-scrollbar { 
        display: none !important;
    }
    a{
        color: #aaa;
    }
    .logo_box{
        position: relative;
        height: calc(35vh - 50px);
        text-align: center;
    }
    .logo_box img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 110px;
    }

    .bottom_box{
        position: relative;
        height: 50vh;
        padding-bottom: 50px;
    }

    .bottom_box .absolute_box{
        position: absolute;
    }

    .appdown{
        margin-top: 30px;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;
    }
    .btn_dwl a {
        max-width: 200px;
        display: inline-block;
        -webkit-tap-highlight-color: rgba(255,255,255,0);
    }

    .btn_dwl a img {
        width: 100%;
    }

    .btn_dwl:first-child a{
        margin-right: 10px;
    }

    @media screen and (min-width: 950px){
        .btn_dwl a {
            margin: 0 10px;
        }
    }
    
    .error {
        font-size: 12px;
        color: #eb80b5;
        margin-left: 5px;
    }

    .mail_btn{
        border-radius: 5px;
        border: 1px solid #4B67F9;
    }
    .issue{
        font-size: 0.8em;
        color: #eb80b5;
    }

    .popup{
    width: 100%;
    height: 100vh;
    min-height: 500px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 150;
    overflow-y: auto;
  }
  .popup .dimm{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .popup_box{
    /* display: block; */
    display: none;
  }
  .popup_box.on{
    display: block;
  }
  .popup_box:first-of-type .dimm{
    background-color: rgba(0, 0, 0, 0.5);
  }
  .popup .content_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 500px;
    /* border: 1px solid #fff */
  }
  .popup .content{
    position: relative;
    width: 100%;
    height: calc(500px - 90px);
    margin-top: 40px;
    /* background-color: #4B67F9; */
    background-color: #fff;
    /* border-radius: 10px; */
    overflow: hidden;
    
  }
  .popup .close_icon{
    color: #fff;
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 10px;

  }
  .popup .nomore_btn{
    color: #fff;
    display: inline-block;
    transform: translate(10px,10px);
    font-size: 15px;
  }
  .popup .tit_box{
    /* background-color: #4B67F9; */
    color: #4B67F9;
    height: 70px;
    text-align: center;
    font-family: 'Fugaz One', sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
  }
  .popup .tit_box h5{
    font-size: 30px;
    font-weight: normal;
    line-height: 80px;
    text-overflow: ellipsis;
  }
  .popup .txt_box{
    text-align: center;
    word-break: break-all;
    padding: 10px 25px;
    overflow-y: auto;
    height: calc(100% - 100px);
    white-space: pre-line;
    font-size: 15px;
    line-height: 1;
  }

  .popup .top_tit{
    font-size: 14px;
    position: absolute;
    top: 8px;
    left: 0;
    color: #fff;
    font-weight: normal;
    max-width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    margin: 0;
    display: inline-block;
    padding: 2px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }

    .text_pale{
        color: #ccc;
    }
  
    .social_menu li{
        border-radius: 100%;
        /* border: 1px solid #eee; */
        margin: 0 5px;
    }
    .social_menu li img{
        width: 40px;
    }

    .setting{
        outline: none;
        color: #541792;
        font-size: 14px;
    }
    .setting:focus{
        box-shadow: none;
    }

    .external_item {
        background: none !important;
        border: none !important;
    }


    .sns_list>*{
        border-radius: 100%;
        overflow: hidden;
        width: 40px;
        height: 40px;
    }

</style>
<style>
    #naver_id_login{
        margin: 0 5px;
    }
</style>