<template>
    <div id="subTopNav">
        <button v-if="this.$route.name != 'Signin'" class="btn setting" @click="$router.go(-1)"><i class="far fa-chevron-left"></i></button>
        <div class="language">
            <span @click="lanuageList=!lanuageList" class="langBtn main_txt">
                <span v-if="language =='ko'"><img src="@/assets/img/flag/094-south-korea.svg" alt="" width="25"><i class="far fa-chevron-down"></i></span>
                <span v-if="language =='en'"><img src="@/assets/img/flag/226-united-states.svg" alt="" width="25"><i class="far fa-chevron-down"></i></span>
                <span v-if="language =='cn'"><img src="@/assets/img/flag/034-china.svg" alt="" width="25"><i class="far fa-chevron-down"></i></span>
                <span v-if="language =='jp'"><img src="@/assets/img/flag/063-japan.svg" alt="" width="25"><i class="far fa-chevron-down"></i></span>
            </span>
            <ul v-if="lanuageList" class="position-absolute select-ul">
                <li @click="SetLocale('ko')">
                    <img src="@/assets/img/flag/094-south-korea.svg" alt="" width="25">Korean</li>
                <li @click="SetLocale('en')">
                    <img src="@/assets/img/flag/226-united-states.svg" alt="" width="25">English</li>
                <li @click="SetLocale('cn')">
                    <img src="@/assets/img/flag/034-china.svg" alt="" width="25">Chinese</li>
                <li @click="SetLocale('jp')">
                    <img src="@/assets/img/flag/063-japan.svg" alt="" width="25">Japanese</li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return{
            lanuageList:false,
            language: this.$route.params.lang,
            lang: 'ko'
        }
    },
    computed:{
        pageTitle(){            
            return this.$store.state.headerTitle
        },
        prevPType(){
            return this.$store.state.prevPage
        },
        prevDashboard(){
            return this.$store.state.pageDashboard
        }
    },
    methods: {                     
        SetLocale: function(lang){            
            const locale= lang;
            this.$router.push({
                params: {lang: locale}
            }).then(
                ()=>{
                    this.$router.go();
                }
            )            
        }
    },
}
</script>

<style scoped>
/* topnav 공통 */
.setting{
    outline: none;
    color: #4B67F9;
    font-size: 14px;
}
.setting:focus{
    box-shadow: none;
}

/* 공통 끝 */
#subTopNav{
    /* position: relative; */
    position: fixed;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5em;
    box-sizing: border-box;
    max-width: 1024px;
}
.subTit{
    font-size: 17px;
    font-weight: 400;
    color: #4B67F9;
}
.transparent{
    opacity: 0;
    cursor: none;
}
.language{
    position: relative;
    margin-left: auto;
    right: 8px;
}
.select-ul{
    transform: translateY(10%);
    background-color: #fbfbfb;
    padding: 10px 9px;
    right: 0;
    box-shadow: 0 3px 3px #eee;
    font-size: 15px;
}
.select-ul li {
    display: flex;
}
.select-ul li img{
    margin-right: 5px;
}
.langBtn i{
    margin-left: 5px;
    font-size: 14px;
}
</style>