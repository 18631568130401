<template>
    <div id="SignUp" class="has_fixed_bottom_box">
        <div class="container">
            <div class="top_txt_box mb-5 mt-4">
                <h3>{{ $t('signup.1') }}</h3>
                <p>{{ $t('signup.23') }}</p>
            </div>
            <div class="form_box">
                <div class="form-group line mb-4 pb-1">
                    <label>{{$t('signup.5')}}</label>
                    <input type="email" class="form-control" ref="emails" :placeholder="$t('signup.3')" v-model="email" @keyup.enter="ReSendEmail" required>
                    <p class="error" v-if="emailErr">{{$t('signup.6')}}</p>
                </div>
            </div>
            <div class="fixed_bottom_box p-3">
                <button type="button" class="btn btn-block btn-primary" @click="ReSendEmail"> <small>{{$t('signup.19')}}</small></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title: this.$t('signin.12'),
            prevP:'type02',
            pageD: false,
            lang: this.$route.params.lang,

            email: '',
            emailErr: false,
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
    },
    methods: {
        CheckEmail : function(){
            var character = this.email;
            if(character == ""){
                this.emailErr = true;
            }
            else{
                var check = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(character);
                if(check){
                    this.emailErr = false;

                }
                else {
                    this.emailErr = true;
                }
            }
        },   
        ReSendEmail(){
            this.CheckEmail();
            const api = this.$store.state.baseURL;
            const email = this.email;

            if(this.email == ""){
                this.$alert(this.$t("findPwd.15"));
                return false;
            }

            if(this.emailErr){
                this.$alert(this.$t('signup.6'));
                return false;
            }

            const lang = this.lang;
            this.$http.post(`${api}/member/sign/re/send`,{email,lang}).then(
                res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                    this.$fire({title:this.$t('signup.24'),html:this.$t('signupAlert.10')}).then(
                        ()=>{
                            this.$router.push({ name: 'Signin' })
                        }
                    )
                    }else if(res.data.code=='100'){
                        this.email = '';
                        this.$alert(this.$t('change.18'));
                    }else if(res.data.code=='110'){
                        this.email = '';
                        this.$alert(this.$t('signup.25')).then(() => {
                            this.$router.push({path:'/'+this.$i18n.locale+'/signin'});
                        });
                    }else if(res.data.code=='300'){
                        this.email = '';
                        this.$alert(this.$t('signup.26')).then(() => {
                            this.$router.push({path:'/'+this.$i18n.locale+'/signin'});
                        });
                    }
                }

                }
            )
        },
    },
}
</script>
<style scoped>
    .infoPp.wtdPw .content{
        /* height: calc(100% - 50px);
        max-height: inherit; */
        height: 100%;
        border-radius: 0;
        max-height: inherit;
        left: 0;
        transform: translateX(0);
        padding-top: 100px !important;
    }
    .infoPp.wtdPw input.wtdPw{
        -webkit-text-security: disc;
    }
    .infoPp.wtdPw input.wtdPw::placeholder{
        font-size:8px;
        color:#ddd;
    }
    .infoPp.wtdPw .circle{
        width: 60px;
        height: 60px;
        border: 5px solid #171c61;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #171c61;
        font-size:1.3em;
        margin: 0 auto;
    }
    .infoPp.wtdPw .fa-times{
        position: absolute;
        top:1em;
        right:1em;
        font-size: 1.5em;
    }
    .error {
        font-size: 12px;
        color: #ff6363;
        margin-left: 5px;
    }
    
    .fixed_bottom_box{
        background-color: #fff;
    }
</style>